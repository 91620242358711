.subtitle {
  align-self: flex-start;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  font-size: 1.5em;

  color: #000000;
  padding: 0.25em 0;
}

.options {
  margin-top: 0.5em;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}
