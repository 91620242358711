@import '../../styles/variables.scss';

.fab {
  width: 56px;
  height: 56px;
  padding: 0.75em;

  background-color: $color_primary;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  bottom: 1.25em;
  right: 1.25em;
  z-index: 50;
}
