@import '../../styles/variables';

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.25em;

  .financialReturnContainer {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    .financialReturnText {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;

      color: #000000;

      opacity: 0.5;
    }
  }
}

.title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  font-size: 0.875em;
  user-select: none;

  color: #000000;

  opacity: 0.5;
}

.amountSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  width: 100%;
  padding-bottom: 0.5em;

  .sign {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    font-size: 1.5em;

    color: #000000;

    .amount {
      font-size: 1.65em;
      transition: all 0.5s;
    }
  }

  .icon {
    padding: 0.5em;
  }

  .iconAndButton {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .notAvailable {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1.65em;

    color: #000000;
  }
}

.divider {
  // position: absolute;
  // top: calc(100% + 1em);
  // left: -1.25em;
  // width: calc(100% + 2.5em);
  height: 1px;
  background-color: #f3f3f3;
  margin-top: 1.25em;
}

.footer {
  width: 100%;
  padding-bottom: 0.5em;
  user-select: none;

  .buttonGroup {
    display: flex;
    justify-content: space-around;

    .button {
      all: unset;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.75em;
      width: 20%;

      &:disabled {
        .icon {
          opacity: 0.3;
        }
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3em;
        height: 3em;
        border-radius: 50%;
        background-color: #f2f2f2;
      }

      span {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 0.875em;
        display: flex;
        align-items: center;

        color: #000000;

        opacity: 0.5;
      }
    }
  }
}
