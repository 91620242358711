@import '../../styles/variables';

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  & > .texts {
    display: flex;
    flex-direction: column;
    gap: 0.25em;

    & > .title {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      font-size: 0.875em;

      color: #000000;
      opacity: 0.5;
    }

    & > .amount {
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      font-size: 1.5em;

      color: #000000;
    }
  }
}

.appLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.divider {
  position: absolute;
  top: calc(100% + 1em);
  left: -1.25em;
  width: calc(100% + 2.5em);
  height: 1px;
  background-color: $color_border;
}

.img {
  position: relative;

  .icon {
    background-color: #fff;
    clip-path: circle(40% at 50% 40%);
    position: absolute;
    bottom: -0.5em;
    left: -0.5em;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
