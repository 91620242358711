.body {
  display: flex;
  position: relative;

  width: 100%;

  .texts {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 80%;

    .title {
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      font-size: 1.25em;

      color: #000000;

      &.short {
        width: 80%;
      }
    }

    .description {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      font-size: 0.75em;

      color: #000000;
      opacity: 0.5;
    }
  }

  .img1 {
    position: absolute;
    bottom: -1em;
    right: -0.9em;
  }
  .img2 {
    position: absolute;
    top: -0.5em;
    right: 2em;
  }
}
