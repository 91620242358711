.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;

  width: 100%;
  height: 70vh;

  .greenText {
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 28px;
    text-align: center;

    color: #67bd67;
  }

  .comment {
    align-self: center;
    width: 75%;
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 28px;
    /* or 117% */

    text-align: center;

    color: #000000;
  }
}
