.subtitle {
  align-self: flex-start;
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  font-size: 1.5em;

  color: #000000;
  padding: 0.25em 0;
}

.inputContainer {
  width: 100%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  .hint {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;

    color: #9d9d9d;
  }
}
