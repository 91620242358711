.subtitle {
  align-self: flex-start;
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  font-size: 1.5em;

  color: #000000;
  padding: 0.25em 0;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  .inputContainer {
    width: 100%;
    align-self: flex-start;
  }

  .alert {
    justify-self: flex-end;
  }
}
