.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.expiredTitle {
  /* Font */
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  line-height: 1.125em;

  /* Size */
  font-size: 0.875em;

  /* Color */
  color: #000000;
}

.expiredSubtitle {
  /* Font */
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  line-height: 15px;

  /* Size */
  font-size: 11px;

  /* Color */
  color: #585858;

  /* Margin */
  margin-top: 0.5em;
}
