.emptyState {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 1em;

  .illustrationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;

    .title {
      color: #000;
      text-align: center;
      font-family: 'Red Hat Display';
      font-size: 1.25em;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
    }
  }

  .descriptionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25em;

    .description {
      color: #000;
      text-align: center;
      font-family: 'Manrope';
      font-size: 0.875em;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      opacity: 0.4;
    }

    .actionText {
      color: #ff691e;
      font-family: 'Manrope';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 13.369px;
    }
  }
}
