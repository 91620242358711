.title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  font-size: 0.875em;

  color: #000000;
  opacity: 0.5;
}

.body {
  width: calc(100% + 2em);
  // min-height: 11.25em;
  margin: -1em 0;
  margin-left: -1em;
  position: relative;

  .cardsContainer {
    width: calc(100% + 2.5em);
    margin-left: -1.25em;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1em;
    padding: 1em 2.5em;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .product {
    min-width: 95%;
    transition: 0.3s linear all;
    scroll-snap-align: center;

    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.15));
  }
}
