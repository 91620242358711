.screen {
  width: 100%;
  height: 100vh;
  padding: 1.25em;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  position: relative;
  perspective: 0px;

  background-color: #daf3de;

  .illustration {
    margin-top: 10em;
  }

  .description {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      font-family: "Red Hat Display";
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      font-size: 1.5em;

      text-align: center;
      color: #4a865b;
    }
  }

  .download {
    position: fixed;
    bottom: 2.5em;
    left: 1.25em;
    right: 1.25em;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    span {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      font-size: 13px;

      text-align: center;
      color: #4a865b;
    }
  }
}
