.cardTitle {
  width: 70%;
  /* Color */
  color: #000;

  /* Font */
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  line-height: 1.5em;

  /* Size */
  font-size: 1.125em;
}

.allApps {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .icons {
    width: 34px;
    height: 34px;
    overflow: hidden;
    border-radius: 50%;
  }
}

.textFooter {
  /* Display */
  flex: 3;

  /* Color */
  color: #fff;

  /* Font */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  line-height: 15px;

  /* Size */
  font-size: 11px;
}

.cardGreyTitle {
  /* Font */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  line-height: 1.125em;

  /* Size */
  font-size: 0.875em;

  /* Color */
  color: #000000;
  opacity: 0.5;
}

.connectedApps {
  /* Size */
  width: 100%;

  /* Display */
  display: flex;
  align-items: center;
  gap: 0.25em;
}

.iconContainer {
  /* Display */
  position: relative;

  /* Size */
  width: 54px;
  height: 54px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.plusCircle {
  /* Size */
  width: 52px;
  height: 52px;

  /* Border */
  border-radius: 50%;
  border: 1px #a4a4a4 solid;

  /* Display */
  display: flex;
  align-items: center;
  justify-content: center;
}

.expiresIcon {
  /* Display */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -0.5em;
  bottom: 0;
  transform: rotate(180deg);

  /* Size */
  width: 1.5em;
  height: 1.5em;

  /* Border */
  border-radius: 50%;

  /* Color */
  background-color: #fff;
}
