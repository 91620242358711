.modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;

  .title {
    width: 80%;
    color: #000;
    text-align: center;
    font-family: 'Red Hat Display';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
}
