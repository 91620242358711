@import '../../styles/variables';

.navbar {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2.25em;
  z-index: 40;

  background-color: #fff;
  border-radius: 0.75em 0.75em 0 0;

  width: 100%;
  height: 80px;
  padding: 0.75em 2em;

  position: fixed;
  bottom: 0;

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 3.125em;

    .label {
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
      font-size: 10px;

      color: #999999;
      transition: color 0.2s linear;

      &.current {
        color: $color_primary;
      }
    }
  }
}
