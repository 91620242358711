@import '../../styles/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  gap: 1em;
  background-color: $color_background__gray__light;
  box-shadow: 0 15px 15px #f2f2f2;
  position: fixed;
  top: 1.25em;
  left: 1.25em;
  right: 1.25em;

  .texts {
    display: flex;
    flex-direction: column;
    gap: 0.25em;

    .title {
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      font-size: 1.375em;
      color: #000000;
    }
  }
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1em;

  .segmented {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 0 1.25em;
    padding-top: 2em;
    padding-bottom: 0.25em;
    background-color: #f2f2f2;
    box-shadow: 0 10px 10px #f2f2f2;
    margin-top: -1em;
  }
}

.emptyReload {
  display: flex;
  gap: 0.5em;
  align-items: center;
  justify-content: center;

  .emptyReloadText {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    line-height: 0.875em;
    font-size: 14px;

    color: #ff691e;
  }
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.availableCardOpacity {
  background: linear-gradient(to top, #fff 30%, transparent 80%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.availableCardBody {
  display: flex;
  align-items: center;
  gap: 0.75em;
  width: 100%;
  min-height: 9em;
  padding: 0 1em;
  position: relative;

  & > .slogan {
    position: absolute;
    top: 60%;
    left: 1em;

    width: 70%;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 24px;

    color: #000;
  }
}

.availableCardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: fit-content;
  padding: 0.75em 1em;
  gap: 1em;
  background-color: #fff;
  z-index: 0;

  & > .description {
    flex: 3;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 0.6875em;
    line-height: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;

    color: #000;
  }
}

.activeDateLow {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 0.6875em;
  line-height: 15px;
  opacity: 0.6;

  color: #000000;
}

.reasonsMessage {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1em;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em;

  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;

  &.showInvalidations {
    opacity: 1;
    visibility: visible;
  }
}

.modalTitle {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  font-size: 1.5em;

  text-align: center;
  color: #000000;
  margin-bottom: 0.5em;
}
