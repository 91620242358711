@import '../../styles/variables.scss';

.webcam {
  position: relative;
  width: 100%;
  height: 100vh;

  background-color: black;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: calc(40vh - calc(90vw * 0.65 / 2));
    left: 5vw;
    width: 90vw;
    height: calc(90vw * 0.65);
    background-color: transparent;
    border: 3px white solid;
    z-index: 2;
    border-radius: 1em;
  }

  &.selfie::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at 50% 40%,
      transparent,
      transparent 9em,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 100) 100%
    );
    z-index: 2;
  }

  .guideText {
    position: absolute;
    top: calc(40vh + calc(90vw * 0.65 / 2) + 2em);
    left: 10vw;

    width: 80vw;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    font-size: 13px;

    text-align: center;
    color: #ffffff;
    z-index: 10;
  }

  &.selfie {
    .guideText {
      position: absolute;
      top: 63%;
      left: 10vw;

      width: 80vw;

      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      font-size: 13px;

      text-align: center;
      color: #ffffff;
      z-index: 10;
    }
  }
}

.webcam video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.webcam::before {
  content: '';
  position: absolute;
  z-index: 5; /* Asegurarse de que los pseudo-elementos estén en la parte superior del video */
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: calc(100% - 2.5em);
  padding: 1.25em;

  z-index: 12;

  .title {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    font-size: 1em;

    color: #ffffff;

    opacity: 0.6;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;

  position: absolute;
  bottom: 2em;
  left: 0;
  right: 0;

  z-index: 12;

  .buttonBorder {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 5.1em;
    height: 5.1em;

    border: 1px solid white;
    border-radius: 50%;

    .button {
      width: 5em;
      height: 5em;

      background-color: $color_primary;
      border: none;
      border-radius: 50%;
    }
  }

  span {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    font-size: 13px;

    color: #ffffff;
  }
}
