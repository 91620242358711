@import '../../styles/variables';

.title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  font-size: 0.875em;
  user-select: none;

  color: #000000;

  opacity: 0.5;
}

.amountSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  width: 100%;
  padding-bottom: 0.5em;

  .sign {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    font-size: 1.5em;

    color: #000000;

    .amount {
      font-size: 1.65em;
      transition: all 0.5s;
    }
  }

  .icon {
    padding: 0.5em;
  }

  .iconAndButton {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .notAvailable {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1.65em;

    color: #000000;
  }
}

.footer {
  width: 100%;
  padding-bottom: 0.5em;
  user-select: none;
}
