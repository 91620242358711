.subtitle {
    align-self: flex-start;
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    font-size: 1.5em;
  
    color: #000000;
    padding: 0.25em 0;
  }
  
  .inputContainer {
    width: 100%;
    align-self: flex-start;
  }
  