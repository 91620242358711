@import '../../styles/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  gap: 1em;
  background-color: $color_background__gray__light;
  box-shadow: 0 15px 15px #f2f2f2;
  position: fixed;
  top: 1.25em;
  left: 1.25em;
  right: 1.25em;

  .texts {
    display: flex;
    flex-direction: column;
    gap: 0.25em;

    .title {
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      font-size: 1.375em;
      color: #000000;
    }
  }

  .hSubtitle {
    /* Font*/
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    line-height: 0.675em;

    /* Size */
    font-size: 0.875em;

    /* Color */
    color: #000000;
  }
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1em;

  .segmented {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 0 1.25em;
    padding-top: 2em;
    padding-bottom: 0.25em;
    background-color: #f2f2f2;
    box-shadow: 0 10px 10px #f2f2f2;
    margin-top: -1em;
  }
}

.emptyReload {
  display: flex;
  gap: 0.5em;
  align-items: center;
  justify-content: center;

  .emptyReloadText {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    line-height: 0.875em;
    font-size: 14px;

    color: #ff691e;
  }
}

.cardsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.debtPerDayCard {
  display: flex;
  flex-direction: column;
  gap: 0.25em;

  & > .debtTitle {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    font-size: 0.875em;

    color: #000000;
    opacity: 0.3;
  }

  & > .debtAmount {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    font-size: 1.5em;

    color: #000000;
  }
}

.availableCardHeader {
  /* Display */
  display: flex;
  flex-direction: column;
  gap: 0.675em;
  z-index: 1;

  & > .availableCardTitle {
    width: 80%;
    min-height: 3.75em;

    /* Font */
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    line-height: 1.25em;

    /* Size */
    font-size: 1.25em;

    /* Color */
    color: #000000;
  }
}

.imgContainer {
  position: relative;
  height: 100%;
  width: 35%;

  & > .imgPosition {
    position: absolute;
    top: 0;
    right: -1em;
  }
}

.availableCardFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .availableCardDescription {
    flex: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;

    /* Font */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    line-height: 15px;

    /* Size */
    font-size: 11px;

    /* Color */
    color: #ffffff;
  }
}

.activeCardHeader {
  /* Display */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Size */
  width: 100%;

  & > .topRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5em;
  }
}

.suffixTitle {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  & > .returned {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    font-size: 1em;

    color: #000000;
  }

  & > .toReturn {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    font-size: 0.675em;

    color: #888888;
  }
}

.finishedCardHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5em;
  width: 100%;

  & > .finishedHeaderTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & > .finishedCardTitle {
    /* Font */
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    /* Size */
    font-size: 1.125em;

    /* Color */
    color: #000000;
  }
}
