.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  .question {
    width: 100%;
    align-self: flex-start;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    font-size: 1.5em;

    color: #000000;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #000000;
    opacity: 0.1;
  }

  .markdown {
    color: #000;
    font-family: 'Manrope';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    ul {
      padding-inline-start: 2em;
    }

    ol {
      padding-inline-start: 2em;
    }

    blockquote {
      opacity: 0.7;
      margin-inline-start: 0;
      margin-inline-end: 0;
      margin-block-start: 0;
      margin-block-end: 0;

      padding-block-start: 1em;
      padding-block-end: 1em;

      p {
        margin-block-start: 0;
        margin-block-end: 0;
        padding-block-start: 0.5em;
        padding-block-end: 0.5em;
      }

      &::before {
        content: '';
        float: left;
        width: 0.35em;
        height: 33px;
        background-color: #000;
        opacity: 0.4;
        margin-right: 1em;
      }
    }

    a {
      color: #169dff;
    }
  }
}
