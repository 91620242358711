.body {
  margin: -0.75em 0;
}

.iconContainer {
  width: 3em;
  height: 3em;
  position: relative;
  overflow: hidden;

  .icon {
    position: absolute;
    top: -5%;
    left: 5%;
  }
}

.title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #000000;
}

.subtitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;

  color: #585858;
}
