@import '../../styles/variables';

.stepCounter {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  border: 1px solid $color_border;
  border-radius: 1em;

  span {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    font-size: 1em;

    color: #000000;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;

  width: 100%;
  margin-bottom: 1em;
}
