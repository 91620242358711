.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .texts {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    .title {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      font-size: 0.875em;

      color: #000000;
      opacity: 0.5;
    }

    .amount {
      font-family: "Red Hat Display";
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      font-size: 1.5em;

      color: #000000;
    }
  }
}
